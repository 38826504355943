import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Task } from 'twilio-taskrouter';
import { HandoffMode } from '@kea-inc/types';

import { initialTaskrouterState } from './state';
import * as _ from './actions';
import { TaskAttributes } from './types';
import * as errors from './errors';

const taskrouterSlice = createSlice({
  name: 'taskrouter',
  initialState: initialTaskrouterState,
  reducers: {
    resetTaskrouterState: (state) => ({
      ...initialTaskrouterState,
      workerToken: state.workerToken,
      workerSid: state.workerSid,
    }),
    setWorkerSid: (state, action: PayloadAction<string>) => {
      state.workerSid = action.payload;
    },
    onReservationCreated: (
      state,
      action: PayloadAction<{
        reservationSid: string;
        taskSid: string;
        taskStatus: Task['status'];
        taskAttributes: TaskAttributes;
      }>,
    ) => {
      const { reservationSid, taskSid, taskStatus, taskAttributes } =
        action.payload;

      state.reservationSid = reservationSid;
      state.taskSid = taskSid;
      state.taskStatus = taskStatus;
      state.taskAttributes = taskAttributes;
      state.timeout = false;
    },
    onAcceptedTask: (state, action: PayloadAction<TaskAttributes>) => {
      state.pending.isAcceptingReservation = false;
      state.errors.hasAcceptReservationError = false;
      state.timeout = false;

      state.taskStatus = 'assigned';
      state.taskAttributes = action.payload;
      state.store = {
        ...state.store,
        ...action.payload.store,
      };
    },
    onUnhandledError: (state) => {
      state.errors.hasUnhandledError = true;
    },
    onReservationCanceled: (state) => {
      state.reservationSid = null;
      state.taskSid = null;
      state.taskStatus = null;
      state.taskAttributes = null;
      state.timeout = false;
    },
    onReservationTimeout: (state) => {
      state.reservationSid = null;
      state.taskSid = null;
      state.taskStatus = null;
      state.taskAttributes = null;
      state.timeout = true;
    },
    onAgentTookAction: (state) => {
      state.agentTookAction = true;
    },
    closeStatusOverlay: (state) => {
      state.isStatusOverlayOpen = false;
    },
    openStatusOverlay: (state) => {
      state.isStatusOverlayOpen = true;
    },
    resetWorkerStatus: (state) => {
      state.workerStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(_.fetchWorkerToken.pending, (state) => {
      state.pending.isFetchingToken = true;
      state.errors.hasFetchTokenError = false;
    });
    builder.addCase(_.fetchWorkerToken.fulfilled, (state, action) => {
      state.pending.isFetchingToken = false;
      state.errors.hasFetchTokenError = false;
      state.workerToken = action.payload;
    });
    builder.addCase(_.fetchWorkerToken.rejected, (state) => {
      state.pending.isFetchingToken = false;
      state.errors.hasFetchTokenError = true;

      errors.fetchWorkerTokenError();
    });

    builder.addCase(_.acceptTask.pending, (state) => {
      state.pending.isAcceptingReservation = true;
      state.errors.hasAcceptReservationError = false;
    });
    builder.addCase(_.acceptTask.rejected, (state) => {
      state.pending.isAcceptingReservation = false;
      state.errors.hasAcceptReservationError = true;
    });

    builder.addCase(_.connectConference.pending, (state) => {
      state.pending.isConnectingToConference = true;
      state.errors.hasConferenceConnectionError = false;
    });
    builder.addCase(_.connectConference.fulfilled, (state, action) => {
      state.pending.isConnectingToConference = false;
      state.errors.hasConferenceConnectionError = false;

      state.conferenceSid = action.payload;
    });
    builder.addCase(_.connectConference.rejected, (state) => {
      state.pending.isConnectingToConference = false;
      state.errors.hasConferenceConnectionError = true;
    });

    builder.addCase(_.completeTask.pending, (state) => {
      state.pending.isCompletingTask = true;
      state.errors.hasCompleteTaskError = false;
    });
    builder.addCase(_.completeTask.fulfilled, (state) => {
      state.pending.isCompletingTask = false;
      state.errors.hasCompleteTaskError = false;
      state.agentTookAction = false;
    });
    builder.addCase(_.completeTask.rejected, (state) => {
      state.pending.isCompletingTask = false;
      state.errors.hasCompleteTaskError = true;
      state.agentTookAction = false;
    });

    builder.addCase(_.fetchBrand.pending, (state) => {
      state.pending.isFetchingBrand = true;
      state.errors.hasFetchBrandError = false;
    });
    builder.addCase(_.fetchBrand.fulfilled, (state, action) => {
      state.pending.isFetchingBrand = false;
      state.errors.hasFetchBrandError = false;
      state.brand = action.payload;
    });
    builder.addCase(_.fetchBrand.rejected, (state) => {
      state.pending.isFetchingBrand = false;
      state.errors.hasFetchBrandError = true;
    });

    builder.addCase(_.fetchStore.pending, (state) => {
      state.pending.isFetchingStore = true;
      state.errors.hasFetchStoreError = false;
    });
    builder.addCase(_.fetchStore.fulfilled, (state, action) => {
      state.pending.isFetchingStore = false;
      state.errors.hasFetchStoreError = false;

      if (!action.payload) {
        return state;
      }

      state.store = {
        ...state.store,
        ...action.payload,
      };

      if (action.payload.oloStoreHandoffMethods) {
        const handoffMethods: HandoffMode[] = [];

        Object.entries(action.payload.oloStoreHandoffMethods).forEach(
          ([key, value]) => {
            if (state.brand?.triageHandoffMethods) {
              if (value === true) {
                let handoffMethod: HandoffMode;
                switch (key) {
                  case 'dispatch':
                    // WORKAROUND: UOA SHOULD NOT KNOW ABOUT OLO HANDOFF METHODS
                    handoffMethod = 'delivery';
                    break;

                  case 'dinein':
                    handoffMethod = 'dine-in';
                    break;

                  default:
                    handoffMethod = key as HandoffMode;
                    break;
                }

                if (
                  state.brand?.triageHandoffMethods[handoffMethod] ||
                  state.brand?.triageHandoffMethods[key as HandoffMode]
                ) {
                  handoffMethods.push(handoffMethod);
                }
              }
            }
          },
        );

        state.handoffMethods = [...new Set(handoffMethods)];
      }

      state.storeHours = action.payload.hours || [];
    });

    builder.addCase(_.fetchBrands.pending, (state) => {
      state.pending.isFetchingBrands = true;
      state.errors.hasFetchBrandsError = false;
    });
    builder.addCase(_.fetchBrands.fulfilled, (state, action) => {
      state.pending.isFetchingBrands = false;
      state.errors.hasFetchBrandsError = false;
      state.brands = action.payload;
    });
    builder.addCase(_.fetchBrands.rejected, (state) => {
      state.pending.isFetchingBrands = false;
      state.errors.hasFetchBrandsError = true;
    });

    builder.addCase(_.fetchStoresByBrand.pending, (state) => {
      state.pending.isFetchingStoresByBrand = true;
      state.errors.hasFetchStoresByBrandError = false;
    });
    builder.addCase(_.fetchStoresByBrand.fulfilled, (state, action) => {
      state.pending.isFetchingStoresByBrand = false;
      state.errors.hasFetchStoresByBrandError = false;
      state.storesByBrand = action.payload;
    });
    builder.addCase(_.fetchStoresByBrand.rejected, (state) => {
      state.pending.isFetchingStoresByBrand = false;
      state.errors.hasFetchStoresByBrandError = true;
    });

    builder.addCase(_.getWorkerStatus.fulfilled, (state, action) => {
      state.workerStatus = action.payload;
    });
    builder.addCase(_.getWorkerStatus.rejected, (state) => {
      errors.workerStatusError();
      state.workerStatus = 'Error';
    });

    builder.addCase(_.reassignTask.pending, (state, action) => {
      state.pending.isReassigningTask = true;
      state.errors.hasReassigningTaskError = false;
      state.reassignedByTtfa = action.meta.arg.isTtfaReassign;
      state.agentTookAction = false;
    });
    builder.addCase(_.reassignTask.rejected, (state) => {
      state.pending.isReassigningTask = false;
      state.errors.hasReassigningTaskError = true;
    });

    builder.addCase(_.createTrainingTask.pending, (state) => {
      state.pending.isCreatingTrainingTask = true;
      state.errors.hasCreateTrainingTaskError = false;
      state.isTrainingTask = false;
    });
    builder.addCase(_.createTrainingTask.fulfilled, (state) => {
      state.pending.isCreatingTrainingTask = false;
      state.errors.hasCreateTrainingTaskError = false;
      state.isTrainingTask = true;
    });
    builder.addCase(_.createTrainingTask.rejected, (state) => {
      state.pending.isCreatingTrainingTask = false;
      state.errors.hasCreateTrainingTaskError = true;
      state.isTrainingTask = false;
    });
  },
});
export const { closeStatusOverlay, openStatusOverlay, resetWorkerStatus } =
  taskrouterSlice.actions;

export const { actions } = taskrouterSlice;

export default taskrouterSlice.reducer;
